import { type FunctionComponent, type MouseEventHandler } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Link } from 'dibs-elements/exports/Link';
import { Badge } from 'dibs-elements/exports/Badge';
import { moreWaysToBrowseHeader } from '../../shared/sharedMessages';
import { SharedPopularSearchesLink } from '../SharedPopularSearchesLink/SharedPopularSearchesLink';
import { PdpSharedSitemapLinks } from '../../pdp/PdpSharedSitemapLinks/PdpSharedSitemapLinks';
import { LINKS_BLOCK_DATA_TN } from '../../constants/sbConstants';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import classnames from 'classnames';
import styles from './SharedMobileLinksBlock.scss';
import { type SharedMobileLinksBlock_viewer$data } from './__generated__/SharedMobileLinksBlock_viewer.graphql';
import { type SharedMobileLinksBlock_itemSearch$data } from './__generated__/SharedMobileLinksBlock_itemSearch.graphql';
import { type SharedMobileLinksBlock_item$data } from './__generated__/SharedMobileLinksBlock_item.graphql';

type Props = {
    viewer: SharedMobileLinksBlock_viewer$data;
    itemSearch?: SharedMobileLinksBlock_itemSearch$data | null | undefined;
    item: SharedMobileLinksBlock_item$data | null | undefined;
    links: Array<{ anchorText: string; url: string }>;
    title?: string;
    anchorTextPrefix?: string;
    onClick?: MouseEventHandler;
    borderTop?: boolean;
    borderBottom?: boolean;
    popularSearchTermClassName?: string;
};

const MIN_LINK_COUNT = 3;

//TODO: refactor to use hooks. It's too difficult to maintain Typescript in current state.
export const SharedMobileLinksBlockComponent: FunctionComponent<Props> = ({
    viewer,
    itemSearch,
    item,
    links,
    title = moreWaysToBrowseHeader,
    anchorTextPrefix,
    onClick = () => {},
    borderTop,
    borderBottom,
    popularSearchTermClassName,
}) => {
    if (!links?.length) {
        return null;
    }

    const validLinks = links.filter(link => link.url && link.anchorText);
    const source = itemSearch || item;

    if (!source || validLinks.length < MIN_LINK_COUNT) {
        return null;
    }

    const { isPopularSearchesEnabled } = viewer;
    const { popularSearchesLink } = source || {};
    // If sitemap links exist, don't show popularSearchesLink (PDP_SEO_SITEMAP_LINKS feature flag is handled in graphql)
    // https://1stdibs.atlassian.net/browse/GROWTH-7869
    const showPopularSearchesLink = !item?.sitemapLinks?.length;

    const wrapperClassName = classnames(styles.wrapper, {
        [styles.borderTop]: borderTop,
        [styles.borderBottom]: borderBottom,
    });

    return (
        <HeadingLevel>
            {Heading => (
                <div className={wrapperClassName} data-tn={LINKS_BLOCK_DATA_TN}>
                    <Heading className={styles.header}>{title}</Heading>
                    <div className={styles.innerWrapper}>
                        <div className={styles.linkContainer}>
                            {validLinks.map(({ anchorText, url }) => (
                                <Link
                                    key={url}
                                    href={url}
                                    dataTn={`${LINKS_BLOCK_DATA_TN}-item`}
                                    className={`${styles.link} ${styles.specify}`}
                                    onClick={onClick}
                                >
                                    <Badge type="pill" size="medium-regular" interactive>
                                        {anchorTextPrefix
                                            ? `${anchorTextPrefix} ${anchorText}`
                                            : anchorText}
                                    </Badge>
                                </Link>
                            ))}
                        </div>
                    </div>

                    {isPopularSearchesEnabled && showPopularSearchesLink && (
                        <SharedPopularSearchesLink
                            popularSearchesLink={popularSearchesLink}
                            popularSearchTermClassName={popularSearchTermClassName}
                        />
                    )}

                    <PdpSharedSitemapLinks item={item} />
                </div>
            )}
        </HeadingLevel>
    );
};

export const SharedMobileLinksBlock = createFragmentContainer(SharedMobileLinksBlockComponent, {
    viewer: graphql`
        fragment SharedMobileLinksBlock_viewer on Viewer {
            isPopularSearchesEnabled: featureFlag(feature: "popularSearches")
        }
    `,
    itemSearch: graphql`
        fragment SharedMobileLinksBlock_itemSearch on ItemSearchQueryConnection {
            popularSearchesLink {
                ...SharedPopularSearchesLink_popularSearchesLink
            }
        }
    `,
    item: graphql`
        fragment SharedMobileLinksBlock_item on Item {
            popularSearchesLink {
                ...SharedPopularSearchesLink_popularSearchesLink
            }
            sitemapLinks {
                text
            }
            ...PdpSharedSitemapLinks_item
        }
    `,
    links: graphql`
        fragment SharedMobileLinksBlock_links on InternalBuyLinkType @relay(plural: true) {
            url
            anchorText
        }
    `,
});
