/**
 * @generated SignedSource<<d450af6293cb6b3afd875ce21ed93d02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedMobileLinksBlock_item$data = {
  readonly popularSearchesLink: {
    readonly " $fragmentSpreads": FragmentRefs<"SharedPopularSearchesLink_popularSearchesLink">;
  };
  readonly sitemapLinks: ReadonlyArray<{
    readonly text: string | null;
  }> | null;
  readonly " $fragmentSpreads": FragmentRefs<"PdpSharedSitemapLinks_item">;
  readonly " $fragmentType": "SharedMobileLinksBlock_item";
};
export type SharedMobileLinksBlock_item$key = {
  readonly " $data"?: SharedMobileLinksBlock_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedMobileLinksBlock_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedMobileLinksBlock_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "popularSearchesLink",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedPopularSearchesLink_popularSearchesLink"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "sitemapLinks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PdpSharedSitemapLinks_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "421729858a1978afc047e66311c4eb7d";

export default node;
